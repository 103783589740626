.choice-button {
    position: absolute;
    width: 13.5%;
    height: 18.4%;
    max-width: 550px;
    cursor: pointer;
    z-index: 10;
    pointer-events: auto;
    transform: translate(-50%, -50%);
    top: 61.8%;
}

@media (max-width: 550px) {
    .choice-button {
        height: 19.4%;
    }
}
@media (max-width: 500px) {
    .choice-button {
        height: 17%;
        top: 60.8% !important;

    }
}
@media (max-width: 440px) {
    .choice-button {
        height: 16%;
        top: 59.8% !important;
    }
}

@media (max-width: 407px) {
    .choice-button {
        height: 14%;
        top: 58% !important;
    }
}

@media (max-width: 394px) {
    .choice-button {
        height: 14%;
        top: 58% !important;
    }
}

@media (max-width: 333px) {
    .choice-button {
        height: 11.3%;
        top: 56.5% !important;
    }
}
@media (max-width: 280px) {
    .choice-button {
        height: 10%;
        top: 55.5% !important;
    }
}
@media (max-width: 235px) {
    .choice-button {
        height: 8%;
        top: 54.5% !important;
    }
}






