.intro_title_general{
    border: 1px solid #FFFFFF;
    background: #DEFDFF;
    color: #04575C;
    border-radius: 12px;
    width: 37px;
    height: 24px;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}
.intro_title_assessment{
    border: 1px solid #FFFFFF;
    background: #EFEFFF;
    color: #2825E8;
    border-radius: 12px;
    width: 37px;
    height: 24px;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}
.intro_description_test{
    margin-top: 8px;
}
.test_title{
    font:var(--h2);
    letter-spacing: var(--h2-letter-spacing);
}
.test_info{
    font:var(--p4-1);
    letter-spacing: var(--p4-letter-spacing);
    color: #686B70;
    margin-top: 24px;
}
.age_selection{
    margin-top: 40px;
}
.age_selection_title{
    font:var(--p3);
    letter-spacing: var(--p3-letter-spacing);
}



.age_group{
    background-color: #DEFDFF;
}
.age_options{
    margin-top: 20px;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.age_label{
    font:var(--p1);
    letter-spacing: var(--p1-letter-spacing);
    color: #000000;
}
.age_range{
    color: #057A7F;
    font-size: 15px;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.375px;
    text-align: left;
}

.age_group_item{
    padding: 40px 20px 20px 20px;
    background-color: #DEFDFF;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    gap:6px;

    width: calc(100% / 2 - 4px);
    position: relative;
}
.age_group_item[disabled]:before{
    content: "";
    position:absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.80);
}
.age_group_item[disabled] .due_date{

    position:absolute;
    top:8px;
    right: 8px;
    backdrop-filter: blur(8px);
    color:var(--neutral-white);
    background-color:var(--primary-900);
    border-radius: 50em;
    width: 56px;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font:800 11px/130% 'Pretendard';
    letter-spacing: var(--caption2-letter-spacing);
}

.age_group_item[disabled] .due_date .date{
    font:400 10px/130% 'Pretendard';
    letter-spacing: var(--tiny1-letter-spacing);
}
.age_group_item_limits{
    padding: 40px 20px 20px 20px;
    background-color: #FFFFFFCC;
    border-radius: 32px;
    width: 100%;
    box-sizing: border-box;
}
.limits{
    color: #00000061;
}
.Delay{
    width: 56px;
    height: 56px;
}

/* ---------일반 검사 디테일--------- */
.childrenDetail .assessment_details{
    padding-right: 24px;
    width: calc(100% - 84px);
}
.childrenDetail .assessment_title,.ChildrenInformation .assessment_title{
    width: 100%;
}
.childrenDetail {
    gap:0;
}

.intro_age_group{
    font-size: 26px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.65px;
    margin: 8px 0px;
}
.intro_payment_group{
    font-size: 26px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.65px;
    margin-top: 8px;
}
.call_payment_wrapper{
    margin-top: 24px;
}
.intro_age_info{
    font-size: 15px;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.375px;
    color: #686B70;
}
.assessment_section{
    margin-top: 16px;
    border-top: 1px solid #F8F8F9;
    border-bottom: 1px solid #F8F8F9;
}
.assessment_title{
    font-size: 18px;
    font-weight: 700;
    line-height: 126%;
    letter-spacing: -0.45px;
}
.assessment_info, .assessment_meta, .gender_selection{
    display: flex;
}
.assessment_info{
    padding: 16px 0px;
}
.assessment_details{
    padding: 8px 0px;
}
.assessment_meta{
    color: #686B70;
    gap: 6px;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.375px;
}
.assessment_bar {
    color: var(--neutral-300);
}

.children_image_container{
    margin-left: auto;
    border-radius: 16px;
    overflow: hidden;
    aspect-ratio: 21/ 25;
    max-width: 84px;
}
.Children_Img{
    width: 100%;
    height: 100%;
    object-fit:cover;
}
.price_info{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    margin-top: 16px;
}
.price_label{
    color: var(--neutral-black, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.4px;
}
.price_value{
    color: var(--neutral-black, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.4px;
}
.intro_body{
    padding: 8px 24px 24px 24px;
}
.input_label{
    color: var(--neutral-900, #212223);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
    letter-spacing: -0.35px;
}
.age_input, .name_input{
    width: 100%;
    border: 1px solid var(--neutral-100, #EEEEEF);
    border-radius: 14px;
    display: flex;
    padding: 0px 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    box-sizing: border-box;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.35px;
    outline: none;
    color:var(--neutral-black);
    transition: all 0.3s;
}
.age_input:focus, .name_input:focus{
    border:1px solid var(--primary-400);
    color: #9C9EA3;  /* 11/21 추가 */
}
.age_input:focus::placeholder, .name_input:focus::placeholder{
    color: #9C9EA3;
}
/* 입력값이 있을 때만 검정색으로 변경 */
.age_input:not(:placeholder-shown), .name_input:not(:placeholder-shown) {
    color: var(--neutral-black);
}


.age_input::placeholder,
.name_input::placeholder {
    color: #9C9EA3;
}
.gender_option{
    height: 46px;
    padding: 0px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border: 1px solid #EEEEEF;
    color: #212223;
    border-radius: 14px;
    font-size: 15px;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.375px;
}
.gender_selection{
    gap: 8px;
    margin-top: 8px;
}
.gender_option.selected {
    color: #3C3D40;
    background: #F4F4F5;
    border: 1px solid #F4F4F5;
}
.age_input_section{
    padding-top: 16px;
}
.gender_input_section, .name_input_section{
    padding-top: 24px;
}
.intro_body{
    padding: 8px 24px 24px 24px;
}
.tab{
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 3px;
    background: var(--neutral-50, #F4F4F5);
    width: 100%;
    height: 4px;
}
.activeTab{
    background: var(--neutral-200, #D0D1D3);
}
.assessment_refund{
    color: var(--primary-500, #01BCC8);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: -0.375px;
    text-decoration-line: underline;
    padding-top: 16px;
    text-underline-offset: 3px;
}
.refund_phrase{
    color: #686B70;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: -0.375px;
    margin: 0px;
    padding: 24px 0;
}
.response-score{
    display: flex;
    height: 52px;
    width: 52px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 34px;
    background: var(--primary-50, #DEFDFF);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.375px;
    color: #08969D;
}
.response-container{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    gap: 13px;
}
.response-option{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}
.question-container{
    text-align: center;
}
.question-number{
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.4px;
    color: #01BCC8;
    margin-top: 24px;
}
.question-text{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-top: 8px;
}
.response-container{
    margin-top: 16px;
}
.response-label{
    color: #666666;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.35px;
}
.activeCheck{
    background-color: #08969D;
    color: #FFFFFF;
}
.image-overlay{
    margin-top: 24px;
}
.Test_Img{
    width: 100%;
    max-height: 480px;
    object-fit:contain;
    object-position: top center;
}
.question-description{
    margin-top: 24px;
    font: var(--p1);
    letter-spacing: var(--p1-letter-spacing );
    text-align: center;
}

/* -----검사 완료 페이지 ---- */
.Complete_image_section {
    width: 100%;
    margin-top: 24px;
}
.Complete_Img {
    width: 100%;
    height: auto;
    border-radius: 32px;
}
.completion_message{
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.65px;
    margin-top: 8px;
}
.completion_text_list{
    margin-top: 24px;
}
.completion_text_item{
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: -0.375px;
    color: #686B70;
}

.age{
    color: #057a7f85;
}
/* ------종합검사------- */
.synthesis-age-option{
    display: flex;
    gap: 8px;
}.synthesis-age-options{
    margin-top: 20px;
}
.synthesis-age-group-item{
    padding: 40px 20px 20px 20px;
    border-radius: 32px;
    width: 100%;
    box-sizing: border-box;
    background: #DEFDFF;
}
.synthesis-age-label{
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%;
    letter-spacing: -0.45px;
    color: #000000;
}
.synthesis-age-range{
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.375px;
    color: #057A7F;
    padding-top: 6px;
}
.assessment_body{
    padding: 24px 24px 40px 24px;
    padding-bottom: 15vh;
}
.synthesis_info{
    display: flex;
}
.intro_content{
    color: #686B70;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: -0.375px;
    padding-top: 16px;
}
.question1_container{
    margin-top: 24px;
}
.question-title{
    padding: 8px;
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%;
    letter-spacing: -0.45px;
}
.response1-container{
    display: flex;
    gap: 8px;
    margin-top: 16px;
}
.response-option1{
    width: 100%;
}
.response-score1{
    display: flex;
    height: 52px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    background: #DEFDFF;
    color: #08969D;
    border-radius: 34px;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.375px;
}
.activeCheck{
    background: #08969D;
    color: #FFFFFF;
}
.result_message{
    color:#686B70;
    padding-top: 24px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: -0.375px;
}
.Ellipse{
    width: 3px;
    height: 3px;
}
.refund_info_item{
    display: flex;
    gap: 10px;
}
.refund_text{
    font: var(--p4-1);
    letter-spacing: var(--p4-letter-spacing);
    color: #686B70;
}
.refund_info_container{
    margin-top: 24px;
    padding-left: 10px;
}

/* ---- 3지선다 ---- */
.three-point-container {
    display: flex;
    justify-content: center;
    gap: 32px;
    margin-top: 20px;
}

.three-point-option {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid #E5E6E9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #999;
    cursor: pointer;
    transition: all 0.2s ease;
}

.three-point-option.active {
    background-color: #2D62EA;
    border-color: #2D62EA;
    color: white;
}

.three-point-option:hover {
    border-color: #2D62EA;
    color: #2D62EA;
}

.three-point-option.active:hover {
    color: white;
}
.question-section-title{
    margin: 20px 0;
    padding: 8px;
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 126%;
    letter-spacing: -0.45px;
    font-size: 20px;

}