/* @media print {
    .report-content {
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
        height: auto !important;
        overflow: visible;


    }

    .legend-label {
        line-height: -25px;
    }

    .report-class {
        line-height: -15px;
    }
    
}

@page {
    size: A4;
    padding: 10px;
} */
@media print {
    /* 전체 페이지 설정 */
    @page {
        size: A4;
        margin: 20mm 0; /* 상하 여백 20mm 설정 */
    }

    /* 기본 컨테이너 설정 */
    .view-report {
        width: 210mm;
        margin: 0 auto;
        background: white;
    }

    .report-content {
        width: 210mm;
        margin: 0;
        padding: 0;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    /* 페이지 나눔 제어 */
    .report-wrapper {
        page-break-inside: avoid;
        break-inside: avoid;
        margin-top: 20mm; /* 각 wrapper의 상단 여백 */
    }

    /* 텍스트 블록 사이의 여백 설정 */
    .desc_box {
        margin-top: 15mm; /* 설명 텍스트 블록 상단 여백 */
        page-break-before: auto;
        page-break-inside: avoid;
    }

    .desc_box + .desc_box {
        margin-top: 20mm; /* 연속된 설명 텍스트 블록 사이 여백 */
    }

    /* 제목과 내용 사이 여백 */
    .report-result-title + .desc_box,
    .summary + .desc_box {
        margin-top: 15mm;
    }

    /* 테이블 관련 설정 */
    .report-table {
        page-break-inside: avoid;
        break-inside: avoid;
        margin-top: 15mm; /* 테이블 상단 여백 */
    }

    /* 차트 관련 설정 */
    .report-graph {
        page-break-inside: avoid;
        margin-top: 15mm; /* 차트 상단 여백 */
    }

    /* 페이지 나눔 시 첫 요소는 상단 여백 제거 */
    .report-content > *:first-child,
    .report-border > *:first-child {
        margin-top: 0;
    }

    /* 연속된 요소들 사이의 여백 조정 */
    .report-wrapper + .report-wrapper,
    .report-border + .report-border {
        margin-top: 20mm;
    }

    /* 배경색 출력 보장 */
    * {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }
}
@font-face {
    font-family: 'yg-jalnan';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 400;
    font-display: swap;
    src: url('/public/fonts/Pretendard-Regular.woff2') format('woff2'),
        url('/public/fonts/Pretendard-Regular.woff') format('woff');
}

/* 공통 */
.view-report {
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.report-content {
    width: 595px;
}

.report-wrapper {
    position: relative;
    z-index: 1;
    width: 595px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
/* 
.report-page {
    padding: 20mm;
    min-height: 257mm; 
    box-sizing: border-box;
}

.report-section {
    break-inside: avoid;
    page-break-inside: avoid;
    margin-bottom: 30px;
}

.report-content {
    margin-bottom: 20px;
}

table, figure, .chart-container {
    break-inside: avoid;
    page-break-inside: avoid;
    margin-bottom: 20px;
} */

.rw01 {
    background-image: url('../../assets/report/report_top.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.rw02 {
    height: auto;
    padding-bottom: 2px;
    background-image: url('../../assets/report/report_middle.png');
    background-repeat: repeat-y;
    background-size: contain;
}

.rw03 {
    height: 46px;
    background-image: url('../../assets/report/report_bottom.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -2px;
    position: relative;
    z-index: 1;
}

.report-title {
    width: 487px;
    height: 32px;
    border-radius: 6px;
    padding: 9px 32px;
    background-color: var(--neutral-700);
    font-family: 'yg-jalnan';
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -2.5%;
    color: var(--neutral-white);
    margin: 54px 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.title-icon1 {
    width: 18px;
    height: 18px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_3530_40497)'%3E%3Crect y='13.0801' width='18.4953' height='6.96058' rx='3.48029' transform='rotate(-45 0 13.0801)' fill='white'/%3E%3Cpath d='M11.4966 11.1459C8.93726 9.99282 7.33647 7.57173 6.85601 6.50532L11.0396 2.46236L12.8677 1.68892C13.2662 1.73579 14.2318 1.91392 14.9068 2.25142C15.7505 2.67329 15.8912 3.37642 16.3482 4.46626C16.7138 5.33814 15.938 6.6108 15.5044 7.13814L11.4966 11.1459Z' fill='black'/%3E%3Cpath d='M6.82031 6.54102C7.96875 8.62696 9.44532 10.1035 11.4609 11.1816' stroke='black' stroke-width='0.9'/%3E%3C/g%3E%3Crect x='0.636396' y='13.0801' width='17.5953' height='6.06058' rx='3.03029' transform='rotate(-45 0.636396 13.0801)' stroke='black' stroke-width='0.9'/%3E%3Cdefs%3E%3CclipPath id='clip0_3530_40497'%3E%3Crect y='13.0801' width='18.4953' height='6.96058' rx='3.48029' transform='rotate(-45 0 13.0801)' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.title-icon2 {
    width: 18px;
    height: 18px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_3530_40500)'%3E%3Crect y='13.0781' width='18.4953' height='6.96058' rx='3.48029' transform='rotate(-45 0 13.0781)' fill='white'/%3E%3Cpath d='M11.25 11.25C9 9.9 7.64971 8.10029 6.75009 6.75035L2.7 10.8L1.8 12.6C1.84687 12.9984 1.634 14.0641 1.9715 14.7391C2.39337 15.5829 3.0965 15.7235 4.18634 16.1806C5.05821 16.5462 6.22266 16.1836 6.75 15.75L11.25 11.25Z' fill='black'/%3E%3Cpath d='M6.82031 6.53906C7.96875 8.625 9.44532 10.1016 11.4609 11.1797' stroke='black' stroke-width='0.9'/%3E%3C/g%3E%3Crect x='0.636396' y='13.0781' width='17.5953' height='6.06058' rx='3.03029' transform='rotate(-45 0.636396 13.0781)' stroke='black' stroke-width='0.9'/%3E%3Cdefs%3E%3CclipPath id='clip0_3530_40500'%3E%3Crect y='13.0781' width='18.4953' height='6.96058' rx='3.48029' transform='rotate(-45 0 13.0781)' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.report-result {
    width: 487px;
    border-radius: 6px;
    padding: 16px 24px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.report-result-title {
    font-size: 15px;
    font-weight: 800;
    white-space: nowrap;
    /* 텍스트를 한 줄로 강제 */
    line-height: 1.2;
}

/* .report-graph {
    width: 400px;
    height: 100px;
} */

.report-class-des{
    width: calc(100% - 24px);
    background-color: #F7F7F7;
    color: #020a2e;
    font-size: 10px;
    border-radius: 6px;
    font-weight: 400;
    margin-bottom: 12px;
    padding: 4px 10px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.report-class-des div{
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 4px;
}
.report-class-none {
    width: calc(100% - 24px);
    /* height: 20px; */
    background-color: #F7F7F7;
    color: #020a2e;
    font-size: 10px;
    display: grid;
    gap: 4px;
    border-radius: 6px;
    font-weight: 400;
    margin-bottom: 12px;
    padding: 4px 10px;
    align-items: center;
    justify-content: center;
}
.report-class {
    width: calc(100% - 24px);
    /* height: 20px; */
    background-color: #F7F7F7;
    color: #020a2e;
    font-size: 10px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 4px;
    border-radius: 6px;
    font-weight: 400;
    margin-bottom: 12px;
    padding: 4px 10px;
    align-items: center;
    justify-content: center;
}
.report-class-gridntwo {
    width: calc(100% - 24px);
    /* height: 20px; */
    background-color: #F7F7F7;
    color: #020a2e;
    font-size: 10px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 4px;
    border-radius: 6px;
    font-weight: 400;
    margin-bottom: 12px;
    padding: 4px 10px;
    align-items: center;
    justify-content: center;
}
.report-class-gridnone{
    width: calc(100% - 24px);
    /* height: 20px; */
    background-color: #F7F7F7;
    color: #020a2e;
    font-size: 10px;
    display: grid;
    gap: 4px;
    border-radius: 6px;
    font-weight: 400;
    margin-bottom: 12px;
    padding: 4px 10px;
    align-items: center;
    justify-content: center;
}

.box_text_report{
    padding: 7px 16px;
    text-align: left;
    font-family: Pretendard;
    font-size: 10px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.25px;
    border-top:1px solid #DEDFE0 ;
}

.box_text_report .bg_text{
    padding: 2px;
}

/* .report-class {
    width: calc(100% - 24px);
    height: 20px;
    background-color: #F7F7F7;
    color: #020a2e;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-weight: 400;
    padding: 2px;
    gap: 4px;
    margin-bottom: 12px;
} */

.report-class span {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    display: inline-block;
}

/* GRAPH */

/* .report-legend {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    font-family: 'Pretendard';
    font-weight: 400;
    font-size: 10px;
} */
.report-legend {
    font-family: 'Pretendard';
    font-weight: 400;
    font-size: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
.report-legend-two {
    font-family: 'Pretendard';
    font-weight: 400;
    font-size: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
}


.legend-item {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 4px 8px;
    font-size: 10px;
}

.legend-color-box {
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    /* 크기 조정 방지 */
}

.legend-label {
    text-align: left;
    font-size: 10px;
}

.report-table {
    width: 487px;
    font-size: 10px;
    background-color: #fff;
    page-break-inside: auto;
}

.report-table table {
    width: 100%;
    border-collapse: collapse;
}

.report-table table thead {
    width: 100%;
    height: 24px;
    background-color: #fff;
}

.report-table th {
    display: table-cell;
    vertical-align: middle;
    background-color: var(--neutral-white);
    border: 1px solid #DEDFE0;
}

.report-table td {
    padding: 12px;
    text-align: center;
    background-color: #F7F7F7;
    vertical-align: middle;
    border: 1px solid #DEDFE0;
    font-weight: 400;
    letter-spacing: -0.025em;
    text-align: center;
}

.report-table table tr td:first-child {
    background: #EBECED;
    color: #000;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    letter-spacing: -0.025em;
    font-family: Pretendard;
    font-size: 10px;
    font-weight: 800;
    line-height: 120%;
}

.report-table table tr td:first-child {
    width: 104px;
}




/* A */
.red-background {
    background-color: #F7F7F7 !important;
    color: var(--neutral-black, #000) !important;
    font-family: Pretendard;
    font-size: 10px;
    font-style: normal;
    font-weight: normal !important;
    line-height: 150%;
    letter-spacing: -0.25px;
}

.reportA table tr td:first-child{
    color: #fff;
    background-color: #0A77D7;
}

.reportA table td:nth-child(2),
.reportA table td:nth-child(3) {
    width: 48px;
}

.reportA table td:nth-child(4) {
    font-weight: 700;
    width: 64px;
}



/* dubbled border */

.report-table tr td:last-child,
.report-table tr th:last-child {
    border-right: none;
}

.report-table tbody:last-child td {
    border-bottom: none;
}

.report-table tr td:first-child,
.report-table tr th:first-child {
    border-left: none;
}

.report-table tr td:not(:last-child) {
    border-right: none;
}

.report-table tr th:not(:last-child) {
    border-right: none;
}

.report-table tr th {
    border-bottom: none;
}

.color_1 {
    background-color: #224054 !important;
    height: 144px !important;
}

.summary {
    border-top: 1px solid #DEDFE0;
    line-height: 120%;
    background-color: #224054 !important;
    color: #fff;
    padding: 6px 16px;
    font-size: 10px;
    font-weight: 800;
}

.line150 {
    text-align: left !important;
    line-height: 150%;
    font-size: 10px;
    letter-spacing: -0.25px;
    font-weight: 400;
}


/* .summary,
.report-table table:nth-of-type(1)
{
    display: none;
} */






/* B1 */
.reportB1 {
    display: flex;
    justify-content: space-between;
    gap: 23px;
    border-top: 1px solid #DEDFE0;
    /* border-bottom: 1px solid #DEDFE0; */

}

.table_box table th {
    border-top: none;
}

.table_box table tr:last-of-type td {
    border-bottom: none;
}

.table_box {
    display: flex;
    flex: 1;
}


/* .reportB1 table th, */
.reportB1 table td {
    width: 75px;
    /* height: 69px; */
    line-height: 150%;
}
.reportB1 table tr td:first-child,
.reportB2 table tr td:first-child {
    background-color: #0A77D7;
    color: #fff;
}
.reportB1 .table_box:not(:last-of-type) table tr th,
.reportB1 .table_box:not(:last-of-type) table tr td {
    border-right: 1px solid #DEDFE0;
}

.reportB1 .table_box:not(:first-child) table tr td,
.reportB1 .table_box:not(:first-child) table tr th {
    border-left: 1px solid #DEDFE0;
}

.reportB1 table td {
    text-align: center;

}

/* reportB1 아래쪽 테이블 */
.box_w {
    width: 100%;
    height: 24px;
    border-top: 1px solid #DEDFE0;
}

.box_h {
    width: 100%;
    height: 20px;
    border-top: 1px solid #DEDFE0;
}

.under-reportB1 td {
    width: calc(100% / 5);
}

.under-reportB1 table tr td:not(:last-of-type) {
    font-weight: 700;
}

.under-reportB1 table tr td:first-child {
    background: #EBECED;
    color: #000;
    font-weight: 800;
    height: 40px;
}

.under-reportB1 table tr:last-of-type td {
    background: #F7F7F7;
    font-weight: 400;
    text-align: left;
    padding: 7px 16px;
    height: fit-content;
}





/* B2 */
.reportB2 table th {
    border-top: 1px solid #DEDFE0;
}
.reportB2 td {
    width: calc(100% / 3);
}

.box_text {
    width: 100%;
    border-top: 1px solid #DEDFE0;
    display: flex;
    align-items: center;
    justify-content: start;
    min-height: 24px;  
}

.box_text>span {
    color: #000;
    text-align: center;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: -0.25px;
    padding: 7px 16px;
    display: flex;       
    align-items: center; 
    height: 100%;   

}

/* 중첩된 span을 위한 스타일 */
.box_text > span span {
    display: inline-flex;  
    align-items: center;
    height: 100%;
}

.box_text span:not(:last-of-type){
    border-right: 1px solid #DEDFE0;
}
.bg_text {
    background: #224054;
    color: #fff !important;
}

.result-report table td {
    border-bottom: none;
}

.result-report table tr td:first-child {
    width: 104px;
    color: #224054;
    background: #DAF0FF;
    line-height: 120%;
    font-size: 10px;
    letter-spacing: -0.25px;
    font-weight: 800;
}

.result-report table tr td:nth-child(2) {
    background: #F2FAFF;
}

.desc_box {
    color: #000;
    padding: 12px;
    font-size: 10px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.25px;
    background: #F7F7F7;
    text-align: left;
    border-top: 1px solid #DEDFE0;
}
.desc_box_detail{
    color: #000;
    padding: 12px;
    font-size: 10px;
    font-weight: 400;
    line-height: 180%;
    letter-spacing: -0.25px;
    background: #F2FAFF;
    text-align: left;
    border-top: 1px solid #DEDFE0;
}

/* B3 */
.under-reportB3 table tr td:first-child {
    background: #EBECED;
    color: #000;
}

.under-reportB3 table tr td:not(:first-child) {
    font-weight: 700;
}

.under-reportB3 table tr:last-of-type td {
    border-bottom: none;
}

.under-reportB3 table td {
    width: calc(100% / 3);
}




/* C1 */
.reportC1 table td {
    /* width: fit-content; */
    height: fit-content;
}

.reportC1 table tr td:first-child,
.reportC1 table tr:first-child td:nth-child(2) {
    background: #EBECED;
    color: #000;
    font-weight: 800;
    line-height: 120%;
    letter-spacing: -0.25px;
    height: 40px;
}

.reportC1 .table_box table:first-child tr:first-child td:first-child {
    background-color: #6E28A8;
    color: #fff;
}

.reportC1 .table_box:last-child table tr:first-child td:first-child {
    color: #fff;
    background-color: #23AD75;
}




/* C2 */
.reportC2 table tr td:first-child {
    width: 80px;
    background: #224054;
    color: #fff;
}

.reportC2 table tr th {
    font-size: 10px;
    font-weight: 800;
    line-height: 100%;
    letter-spacing: -0.25px;
}

.reportC2 table tr th:nth-of-type(2) {
    width: 180px;
    color: #6E28A8;
}

.reportC2 table tr th:nth-of-type(4) {
    width: 180px;
    color: #23AD75;
}



/* C3 */
.reportC3 table td:nth-child(2),
.reportC3 table td:nth-child(3){
    width: 48px;
    height: 50px;
}
.reportC3 table td:nth-child(4){
    width: 64px;
    height: 50px;
}

/* 59 */
.type-classification-table {
    margin-top: 20px;
    width: 100%;
}

.type-classification-table table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}

.type-classification-table th,
.type-classification-table td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 10px;
}

.type-classification-table th {
    background-color: #f5f5f5;
}

.type-classification-table td[rowspan="2"] {
    vertical-align: middle;
}